@import '../../assets/colors.scss';

.Box {
  width: 20px;
  height: 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: rgba(238, 238, 238, 0.3);
  }
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    width: auto;
    font-size: 10px;
  }
  &.Extra {
    align-items: flex-start;
  }
  &.Header {
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    @media (max-width: 500px) {
      font-size: 14px;
    }
    .LabelWrapper {
      transform: translateX(-30px);
    }
    .Extra {
      font-weight: 600;
      font-size: 14px;
      display: grid;
      @media (max-width: 550px) {
        display: flex;
      }
      div:not(.Replace) {
        @media (max-width: 550px) {
          display: none;
        }
        @media (min-width: 550px) {
          display: block;
        }
      }
      .Replace {
        @media (min-width: 550px) {
          display: none;
        }
        @media (max-width: 550px) {
          display: block;
        }
      }
    }
  }
}

li {
  @media (max-width: 500px) {
    font-size: 10px;
  }
}

.LabelWrapper {
  width: 370px;
  margin-top: 10px;
  label {
    font-size: 12px;
  }
  input {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding-inline: 10px;
    height: 30px;
    font-size: 15px;
    width: 250px;
    @media (max-width: 630px) {
      width: 150px;
    }
    @media (max-width: 500px) {
      width: 100px;
      font-size: 10px;
    }
    @media (max-width: 430px) {
      width: 55px;
    }
  }
  .ExtraReasonWrapper {
    margin-block: 5px;
  }
}

.RateWrapper {
  display: grid;
  width: 250px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  
  div {
    display: flex;
    justify-content: center;
    width: 25px;
  }
}

.ExtrasWrapper {
  display: flex;
  // display: flex;

  div {
    display: flex;
    justify-content: center;
    .Box {
      margin-right: 10px;
    }
    div {
      display: flex;
      align-items: center;
    }
  }

  .Ya {
    margin-right: 20px;
  }

  .Reason {
    grid-area: 'reason'
  }

  input {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding-inline: 10px;
    height: 17px;
    width: 120px;
  }
}

.QuizOrderList {
  list-style-type: lower-alpha;
  li {
    margin-bottom: 10px;
  }
}
