@import '../../assets/colors.scss';

.MasterChartContainer {
  width: 1fr;
  padding-inline: 50px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  overflow-x: auto;
  display: grid;
  grid-template-columns: 400px 400px 400px 400px; // Affect how much cards
    height: 250px;
    column-gap: 20px;
  // @media (min-width: 1285px) {
  //   grid-template-columns: 500px 500px 500px; // Affect how much cards
  //   height: 250px;
  //   column-gap: 20px;
  // }
  // @media (max-width: 1285px) {
  //   grid-template-columns: 1fr;
  //   grid-template-rows: 250px; // Affect how much cards
  //   row-gap: 20px;
  // }
  @media (max-width: 400px) {
    display: none;
  }
  .ChartCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    div {
      .LightBlue {
        color: $PERMATA_LIGHT_BLUE;
        font-weight: 600;
      }
      .LightGrey {
        color: $LIGHT_GREY;
        font-weight: 600;
      }
      span {
        @media (max-width: 440px) {
          display: none;
        }
        font-family: 'Poppins', sans-serif;
      }
    }
  }
}

.ChartContainer {
  width: 1fr;
  margin-inline: 50px;
  margin-bottom: 20px;
  display: grid;
  @media (min-width: 840px) {
    grid-template-columns: 1fr 1fr;
    height: 250px;
    column-gap: 20px;
  }
  @media (max-width: 840px) {
    grid-template-columns: 1fr;
    grid-template-rows: 250px 250px;
    row-gap: 20px;
  }
  @media (max-width: 400px) {
    display: none;
  }
  .ChartCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    div {
      .LightBlue {
        color: $PERMATA_LIGHT_BLUE;
        font-weight: 600;
      }
      .LightGrey {
        color: $LIGHT_GREY;
        font-weight: 600;
      }
      span {
        @media (max-width: 440px) {
          display: none;
        }
        font-family: 'Poppins', sans-serif;
      }
    }
  }
}

.Container {
  min-height: 78vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.ContentCard {
  width: 1fr;
  background-color: #fff;
  flex: 1;
  margin: 0 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
}

.ContentHelperWrapper {
  border-radius: 10px;
  display: flex;
  padding: 15px;
}

.ContentSearchWrapper {
  flex: 7;
  padding-right: 15px;
}

.ContentTable {
  padding-inline: 10px;
  height: 905px;
  flex: 1;
  display: flex;
  flex-direction: column;
  .Table {
    flex: 1;
  }
  
  .TablePagination {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
  }
}

.ContentButtonWrapper {
  flex: 2;
  padding-left: 15px;
  padding-right: 15px;
}

.ExportWrapper {
  margin: 10px;
}

.ErrorWrapper {
  z-index: 3;
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 900px) {
    bottom: -60px;
  }
  @media (max-width: 645px) {
    bottom: -80px;
  }
  @media (max-width: 497px) {
    bottom: -100px;
  } 
  &.None {
    display: none;
  }
}

.DisplayNone {
  display: none;
}

.BoxRegister {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 30px;
  .BoxTitle {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    span {
      font-size: 50px;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 1000px) {
        font-size: 30px;
      }
      @media (max-width: 600px) {
        font-size: 24px;
      }
    }
  }
  
  .BoxContent {
    display: grid;
    height: 70%;
    width: 100%;
    row-gap: 30px;
    @media (min-width: 900px) {
      grid-auto-flow: column;
      grid-template-rows: repeat(3, 60px);
      column-gap: 30px;
    }
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
    .FormControl {
      width: 1fr;
      height: 50px;
    }
    .SubmitControl {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      text-transform: uppercase;
      button {
        background-color: $PERMATA_LIGHT_BLUE;
        width: 270px;
        height: 100%;
        border-radius: 5px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.MuiModal {
  overflow-y: auto;
}

.RefreshButton {
  user-select: none;
  width: 55px;
  &:hover {
    cursor: pointer;
  }
}

.RefreshButtonWrapper {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegRespondenButton {
  background-color: $PERMATA_LIGHT_BLUE;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  &:hover {
    opacity: 0.7;
  }

  span {
    @media (max-width: 1200px) {
      font-size: 12px; 
    }
  }
}

.VerticalLine {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  margin-block: 4px;
}

