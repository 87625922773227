@import '../../assets/colors.scss';

.AbsPermataLogo {
  position: absolute;
  top: 10px;
  right: 10px;
  user-select: none;
  z-index: 1;
  img {
    width: 200px;
    height: 200px;
    transform: translateY(-60px);
    &:hover {
      cursor: pointer;
    }
  }
}

.Container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
