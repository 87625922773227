@import '../../assets/colors.scss';

.Container {
  height: 100vh;
  width: 100vw;
  background-color: $PERMATA_DARK_BLUE;
  display: flex;
  align-items: center;
}

.LittleLogoWrapper {
  top: 0;
  img {
    width: 350px;
    aspect-ratio: 1.5;
  }
  @media (min-width: 1000px) {
    display: none;
  }
}

.LogoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  // background-color: red;
  img {
    aspect-ratio: 1.5;
    width: 80%;
  }
  span {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 30px;
    transform: translateY(-170px) translateX((-18px));
  }
  @media (max-width: 1000px) {
    display: none;
  }
}

.FormWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    justify-content: flex-start;
  }
}

.FormCard {
  background-color: #fff;
  width: 400px;
  height: 350px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  @media (max-width: 450px) {
    width: 300px;
  }
}

.FormControl {
  margin: 30px;
  height: 55px;
  &.FirstItem {
    margin-top: 60px;
  }
}

.SubmitControl {
  display: flex;
  margin: 40px 30px;
  justify-content: center;
  user-select: none;
  button {
    height: 50px;
    width: 200px;
    background-color: $PERMATA_LIGHT_BLUE;
    border-radius: 10px;
    transition: 0.2s;
    span {
      font-size: 20px;
      text-transform: uppercase;
      font-family: 'Poppins', sans-serif;
      color: #fff;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: 0.2s;
    }
  }
}