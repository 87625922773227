@import '../../assets/colors.scss';

.ButtonWrapper {
  user-select: none;
  color: #fff;
  border-radius: 15px;
  min-width: 100px;
  height: 50px;
  display: flex;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: 0.2s;
  border: 1px solid inherit;
  &:hover {
    transition: 0.2s;
    cursor: pointer;
  }
}
