@import '../../assets/colors.scss';

.Container {
  min-height: 100vh;
  width: 100vw;
  background-color: $PERMATA_LIGHT_BLUE;
}

.LogoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: 300px;
    transform: translateY(-80px);
    @media (max-width: 450px) {
      height: 250px;
      transform: translateY(-80px);
    }
  }
}

.Content {
  // transform: translateY(-150px);
  margin-top: -150px;
}

.QuizTitleWrapper {
  position: absolute;
  right: 50px;
  top: 30px;
  text-transform: uppercase;
  @media (max-width: 700px) {
    right: 30px;
    top: 25px;
  }
  @media (max-width: 600px) {
    right: 10px;
    top: 20px;
  }
  @media (max-width: 450px) {
    top: 10px;
  }
  .United {
    transform: translateX(-100px);
    @media (max-width: 700px) {
      transform: translateX(-50px);
    }
    @media (max-width: 600px) {
      transform: translateX(-20px);
    }
    span {
      font-size: 50px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      color: #fff;
      @media (max-width: 450px) {
        font-size: 35px;
      }
    }
  }
  
  .Divided {
    :first-child {
      transform: translateX(-120px);
      @media (max-width: 700px) {
        transform: translateX(-40px);
      }
      @media (max-width: 600px) {
        transform: translateX(-20px);
      }
    }
    span {
      font-size: 30px;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      color: #fff;
      @media (max-width: 600px) {
        font-size: 25px;
      }
      @media (max-width: 450px) {
        font-size: 20px;
      }
    }
  }
}