@import '../../assets/colors.scss';

.Container {
  width: 100vw;
  padding-bottom: 25px;
}

.DisplayNone {
  display: none;
}

.ErrorWrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.FormDivider {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  width: 1fr;
  padding: 20px 40px;
  &.TKO {
    grid-template-columns: 1fr 2fr;
  }
  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
  }
}

.FormBlockDivider {
  padding: 0px 40px 20px 40px;
}

.IdentityWrapper {
  display: flex;
  padding: 20px 40px;
  .FormControl {
    margin: 10px;
  }
}

.FormControl {
  .AreaInvolvement {
    display: flex;
    height: 90%;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
  &.PhoneExt {
    display: flex;
    .Phone {
      flex: 10;
      margin-right: 5px;
    }
    .Ext { 
      flex: 4;
    }
  }
}

.PhoneInputBox {
  width: 350px;
  height: 200px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  @media (max-width: 430px) {
    width: 250px;
    height: 200px;
  }
}

.FindByPhoneHint {
  width: 100%;
  text-align: justify;
  margin-bottom: 20px;
  span {
    font-size: 12px;
    span {
      font-weight: bold;
    }
  }
}

.PhoneInputActionWrapper {
  margin-top: 10px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.SubmitWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  .SubmitHandler {
    width: 200px;
    background-color: $PERMATA_DARK_BLUE;
    transition: 0.2s;
    user-select: none;
    @media (max-width: 550px) {
      width: 150px;
      height: 40px;
      span {
        font-size: 16px;
      }
    }
    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: 0.2s;
    }
  }
}

.MultiOptions {
  display: flex;
  height: 40px;
}

.RespondenArea {
  row-gap: 15px;
  &.TKO {
    display: grid;
    grid-template-columns: 1fr;
  }
  &.CC {
    column-gap: 15px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 2.5fr;
    @media (max-width: 400px) {
      display: flex;
      flex-direction: column;
    }
  }
  .EndGrid {
    display: grid;
    row-gap: 12px;
  }
}

.SubAreaTitle {
  margin-block: 20px;
  span {
    font-family: 'Noto Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    @media (max-width: 1370px) {
      font-size: 30px;
    }
    @media (max-width: 1050px) {
      font-size: 40px;
    }
    @media (max-width: 750px) {
      font-size: 30px;
    }
    @media (max-width: 500px) {
      font-size: 20px;
    }
    @media (max-width: 450px) {
      font-size: 16px;
    }
  }
}

.QuizForm {
  background-color: #fff;
  margin-inline: 25px;
  width: 1fr;
  border-radius: 10px;
  min-height: 640px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow-x: auto;
  @media (max-width: 400px) {
    margin-inline: 10px;
  }
}
