@import '../../assets/colors.scss';

.RefreshButton {
  user-select: none;
  width: 55px;
  &:hover {
    cursor: pointer;
  }
}

.RefreshButtonWrapper {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}