@import '../../assets/colors.scss';

.DisabledBox {
  width: 30px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  background-color: rgba(238, 238, 238, 0.3);
  opacity: 0.7;
  align-items: center;
  @media (max-width: 500px) {
    width: 25px;
  }
}

.Box {
  width: 30px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 25px;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(238, 238, 238, 0.3);
  }
}

.Wrapper {
  user-select: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.DisabledLabel {
  opacity: 0.7;
  @media (max-width: 500px) {
    font-size: 10px;
  }
}

.Label {
  @media (max-width: 500px) {
    font-size: 10px;
  }
}
