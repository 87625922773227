@import '../../assets/colors.scss';

.BoxFilter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 800px;
  padding-block: 20px;
  padding-inline: 10px;
  @media (max-width: 850px) {
    width: 80%;
  }
}

.Container {
  min-height: 78vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.ContentButtonWrapper {
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
}

.ContentCard {
  width: 1fr;
  background-color: #fff;
  flex: 1;
  margin: 0 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
}

.ContentHelperWrapper {
  border-radius: 10px;
  display: flex;
  padding: 15px;
}

.ContentSearchWrapper {
  flex: 7;
  padding-right: 15px;
}

.ContentTable {
  padding-inline: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  .Table {
    flex: 1;
  }
  .TablePagination {
    display: flex;
    justify-content: flex-end;
    height: 50px;
    align-items: center;
  }
}

.DisplayNone {
  display: none;
}

.FilterHelperWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .FilterWrapper {
    display: flex;
    width: 100%;
    @media (max-width: 850px) {
      flex-direction: column;
    }
  }
  .FilterSubmitWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.Invisible {
  visibility: hidden;
}

.FilterByWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  padding-inline: 10px;
}

.FilterSortWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  padding-inline: 10px;
}

.FilterExtraWrapper {
  height: 100%;
  display: flex;
  flex: 1;
  &.DisplayNone {
    display: none;
  }
  padding-inline: 10px;
}

.RefreshButton {
  user-select: none;
  width: 55px;
  &:hover {
    cursor: pointer;
  }
}

.RefreshButtonWrapper {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FilterButton {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  &:hover {
    opacity: 0.7;
  }

  span {
    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
}

.VerticalLine {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  margin-block: 4px;
}