@import '../../assets/colors.scss';

.Container {
  min-height: 100vh;
  width: 100vw;
  background-color: $PERMATA_DARK_BLUE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LogoWrapper {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  top: 0px;
  left: 0px;
  user-select: none;
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 300px;
    transform: translateY(-80px);
  }
}

.Content {
  // transform: translateY(-150px);
  margin-top: 150px;
  z-index: 2;
}

.DrawerButton {
  &.Logout {
    &:hover {
      color: #bb2124;
      .DrawerButtonIcon {
        color: #bb2124;
      }
    }
  }
}

.IdentityWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Poppins Sans', sans-serif;
  margin-block: 20px;
  padding-inline: 20px;
  .NameWrapper {
    font-size: 20px;
    font-weight: 600;
  }
  .NrkWrapper {
    font-size: 18px;
  }
}

.MoreButtonWrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.MoreButton {
  height: 70px;
  width: 70px;
  .MoreButtonIcon {
    height: 30px;
    width: 30px;
    color: #fff;
  }
}